import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import { Link } from "react-router-dom";

import {
  convertDateToHumanDate,
  convertFirstLetterToUpperCase,
} from "../../helpers/functions";
import Loader from "../../Fragments/Loader";
import { useContext, useEffect, useState } from "react";
import Footer from "../../Fragments/Footer";
import getListOfProducts, {
  deleteProduct,
  getProductByFilterName,
  getQueryProducts,
} from "../../Services/productService";
import { Product } from "../../type/product";
import { AuthContext } from "../../context/authContext";
import {
  populateCategories,
  sortProductsByCategory,
} from "../../Services/categoryService";
import { CategoryPopulate } from "../../type/category";
import Multiselect from "multiselect-react-dropdown";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";
import QuickUpdateProduct from "./QuickUpdateProduct";
import axios from "axios";

const truncateAndBreakLines = (text: string, charLimit: number): any => {
  if (text.length > charLimit) {
    const truncatedText = text.substring(0, charLimit);
    const remainingText = text.substring(charLimit);
    return (
      <>
        {truncatedText} <br />
        {truncateAndBreakLines(remainingText, charLimit)}
      </>
    );
  }
  return text;
};

function ListProduct() {
  const { productsModule } = useContext(AuthContext);
  const [count, setCount] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loader, setLoader] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);
  const [category, setCategory] = useState<CategoryPopulate[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number>(0);
  const [excelFile, setExcelFile] = useState<any>("");
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleImport = async () => {
    setButtonLoading(true);
    if (!excelFile) {
      setError("Please select a file to import");
      setButtonLoading(false);
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }

    const formData = new FormData();
    formData.append("file", excelFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/products/import/excel/products/code`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.message) {
        setSuccessMessage(response.data.message);
        setButtonLoading(false);
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
      }
    } catch (error) {
      setError("An error occurred while importing the file");
      setButtonLoading(false);
      setTimeout(() => {
        setError("");
      }, 2000);
    }
  };

  const [quickUpdateDetails, setQuickUpdateDetails] = useState({
    show: false,
    product: null,
  });

  const handleOpenModal = (product: any) => {
    setQuickUpdateDetails({
      show: true,
      product: product,
    });
  };

  const handleCloseModal = () => {
    setQuickUpdateDetails({
      show: false,
      product: null,
    });
  };

  const [page, setPage] = useState(1);
  const limit = 50;

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  const totalPages = Math.ceil(count / limit);

  // const pageLinks = Array.from({ length: totalPages }, (_, index) => index + 1);

  async function init() {
    setLoader(true);
    const response = await getListOfProducts(page, limit);
    if (response.output.data.length > 0) {
      setProducts(response.output.data);
      setCount(response.output.totalCount);
      setError("");
      setLoader(false);

      const categoryResponse = await populateCategories();

      if (categoryResponse.length > 0) {
        setCategory(categoryResponse);
      } else {
        setCategory([]);
      }
    } else {
      setProducts([]);
    }
  }

  async function handleSelectChange() {
    const response = await populateCategories();

    if (response.length > 0) {
      setCategory(response);
    } else {
      setCategory([]);
    }
  }

  async function handleCategorySort(e: any) {
    const response = await sortProductsByCategory(e.toString());
    if (response.length > 0) {
      setProducts(response);
      setError("");
      setLoader(false);
    } else {
      setProducts([]);
    }
  }

  async function handleDelete(id: number) {
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) {
      return;
    }
    const response = await deleteProduct(id);
    if (response?.message) {
      setSuccessMessage(response.message);
      setTimeout(() => {
        setSuccessMessage("");
      }, 2000);
    }
    if (selectedCategory > 0) {
      handleCategorySort(selectedCategory);
      setSelectedOption("a");
    } else {
      init();
      setSelectedOption("a");
    }
  }

  async function handleOptions(e: any) {
    setLoader(true);
    if (e.target.value === "all") {
      return init();
    }
    if (e.target.value === "asc") {
      const response = await getProductByFilterName(e.target.value);
      if (response.data.length > 0) {
        setProducts(response.data);
        setError("");
        setLoader(false);
      } else {
        setProducts([]);
      }
    }
    if (e.target.value === "desc") {
      const response = await getProductByFilterName(e.target.value);
      if (response.data.length > 0) {
        setProducts(response.data);
        setError("");
        setLoader(false);
      } else {
        setProducts([]);
      }
    }
    if (e.target.value === "active") {
      const response = await getProductByFilterName(e.target.value);
      if (response.data.length > 0) {
        setProducts(response.data);
        setError("");
        setLoader(false);
      } else {
        setProducts([]);
      }
    }
    if (e.target.value === "inactive") {
      const response = await getProductByFilterName(e.target.value);
      if (response.data.length > 0) {
        setProducts(response.data);
        setError("");
        setLoader(false);
      } else {
        setProducts([]);
      }
    }
    if (e.target.value === "latest") {
      const response = await getProductByFilterName(e.target.value);
      if (response.data.length > 0) {
        setProducts(response.data);
        setError("");
        setLoader(false);
      } else {
        setProducts([]);
      }
    }
    if (e.target.value === "best_seller") {
      const response = await getProductByFilterName(e.target.value);
      if (response.data.length > 0) {
        setProducts(response.data);
        setError("");
        setLoader(false);
      } else {
        setProducts([]);
      }
    }
    if (e.target.value === "featured") {
      const response = await getProductByFilterName(e.target.value);
      if (response.data.length > 0) {
        setProducts(response.data);
        setError("");
        setLoader(false);
      } else {
        setProducts([]);
      }
    }
    if (e.target.value === "popular") {
      const response = await getProductByFilterName(e.target.value);
      if (response.data.length > 0) {
        setProducts(response.data);
        setError("");
        setLoader(false);
      } else {
        setProducts([]);
      }
    }
    if (e.target.value === "hot_deals") {
      const response = await getProductByFilterName(e.target.value);
      if (response.data.length > 0) {
        setProducts(response.data);
        setError("");
        setLoader(false);
      } else {
        setProducts([]);
      }
    }
    if (e.target.value === "out_of_stock") {
      const response = await getProductByFilterName(e.target.value);
      if (response.data.length > 0) {
        setProducts(response.data);
        setError("");
        setLoader(false);
      } else {
        setProducts([]);
      }
    }

    if (e.target.value === "price_h_l") {
      const response = await getProductByFilterName(e.target.value);
      if (response.data.length > 0) {
        setProducts(response.data);
        setError("");
        setLoader(false);
      }
    }

    if (e.target.value === "price_l_h") {
      const response = await getProductByFilterName(e.target.value);
      if (response.data.length > 0) {
        setProducts(response.data);
        setError("");
        setLoader(false);
      }
    }
    setLoader(false);
  }

  const MAX_PAGE_DISPLAY = 10;

  const renderPageLinks = () => {
    const startPage = Math.max(1, page - Math.floor(MAX_PAGE_DISPLAY / 2));
    const endPage = Math.min(totalPages, startPage + MAX_PAGE_DISPLAY - 1);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };
  async function handleSearch(e: any) {
    if (e.target.value === "") {
      return init();
    }
    const output = await getQueryProducts(e.target.value);
    if (output.data.length > 0) {
      setProducts(output.data);
    }
  }

  // async function getProductsByName() {
  //   return setProducts(await getProductsByName(selectedLimit));
  // }

  useEffect(() => {
    init();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {productsModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content ">
            <div className="mb-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">Products</li>
                </ol>
              </nav>
            </div>
            {successMessage && (
              <div className="alert alert-success" role="alert">
                {successMessage}
              </div>
            )}
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            )}
            <div>
              <div className="card">
                <div className="card-body">
                  <div className="d-none d-md-flex">Total {count} Products</div>
                  <div className="row my-3">
                    <div className="col-md-3">
                      <select
                        onChange={(e) => handleOptions(e)}
                        name="kt_ecommerce_products_table_length"
                        aria-controls="kt_ecommerce_products_table"
                        className="form-select"
                        defaultValue={selectedOption}
                      >
                        <option value={"all"}>All</option>
                        <option value="desc">Desc</option>
                        <option value="asc">Asc</option>
                        <option value="active">Active</option>
                        <option value="inactive">In Active</option>
                        <option value="latest">Latest</option>
                        <option value="best_seller">Best Seller</option>
                        <option value="featured">Featured</option>
                        <option value="popular">Popular</option>
                        <option value="hot_deals">Hot Deals</option>
                        <option value="out_of_stock">Out of Stock</option>
                        <option value="price_h_l">Price H-L</option>
                        <option value="price_l_h">Price L-H</option>
                      </select>
                    </div>
                    <div className="col-md-4">
                      <Multiselect
                        className="form-control"
                        options={category}
                        style={{ zindex: 9999 }}
                        displayValue="category_name"
                        placeholder="Select Category"
                        selectionLimit={1}
                        onSearch={handleSelectChange}
                        onSelect={(selectedList) => {
                          const selectedCategories = selectedList.map(
                            (item: any) => item.category_id
                          );
                          handleCategorySort(selectedCategories[0]);
                          setSelectedCategory(selectedCategories[0]);
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <input
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => handleSearch(e)}
                      />
                    </div>
                    <div className="col-md-2">
                      <Link
                        to="/products/add"
                        className="btn btn-primary "
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Add Products
                      </Link>
                    </div>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        placeholder="Search..."
                        type="file"
                        onChange={(e) =>
                          setExcelFile(e.target.files && e.target.files[0])
                        }
                      />
                    </div>
                    <div className="col-md-2">
                      <button
                        className="btn btn-primary "
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={handleImport}
                        disabled={buttonLoading}
                      >
                        {buttonLoading ? "Uploading..." : "Import"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="table-responsive"
                style={{ overflow: "hidden", outline: "none" }}
                tabIndex={1}
              >
                {loader ? (
                  <Loader />
                ) : (
                  <>
                    <nav className="mt-4" aria-label="Page navigation example">
                      <ul className="pagination justify-content-center">
                        <li
                          className={`page-item ${
                            page === 1 ? "disabled" : ""
                          }`}
                        >
                          <button
                            className="btn btn-sm btn-primary mx-2"
                            onClick={handlePrevPage}
                            disabled={page === 1}
                          >
                            Previous Page
                          </button>
                        </li>
                        {renderPageLinks().map((pageNum) => (
                          <li
                            className={`page-item ${
                              pageNum === page ? "active" : ""
                            }`}
                            key={pageNum}
                          >
                            <button
                              className="page-link"
                              onClick={() => setPage(pageNum)}
                            >
                              {pageNum}
                            </button>
                          </li>
                        ))}
                        <li
                          className={`page-item ${
                            page === totalPages ? "disabled" : ""
                          }`}
                        >
                          <button
                            className="btn btn-sm btn-primary mx-2"
                            onClick={handleNextPage}
                            disabled={page === totalPages}
                          >
                            Next Page
                          </button>
                        </li>
                      </ul>
                    </nav>
                    <table className="table table-custom table-lg mb-0 mx-2">
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Image</th>
                          <th>Name</th>
                          <th>Active</th>
                          <th>Created At</th>
                          <th>Created By</th>
                          <th>Updated At</th>
                          <th>Updated By</th>
                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {products.length > 0
                          ? products.map((output, index) => (
                              <tr key={index}>
                                <td>
                                  <Link to={`/user/add/${output.product_id}`}>
                                    {index + 1}
                                  </Link>
                                </td>
                                <td>
                                  <img
                                    src={
                                      output.product_featured_image !== null &&
                                      output.product_featured_image.length > 0
                                        ? output.product_featured_image
                                        : "/assets/images/default.png"
                                    }
                                    alt="Featured"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "10px",
                                    }}
                                  />
                                </td>

                                <td>
                                  {truncateAndBreakLines(
                                    output.product_name,
                                    35
                                  )}
                                </td>

                                <td
                                  style={{
                                    color:
                                      output.product_active === 1
                                        ? "green"
                                        : "red",
                                  }}
                                >
                                  {output.product_active === 1 ? "Yes" : "No"}
                                </td>

                                <td>
                                  {convertDateToHumanDate(
                                    output.product_created_date
                                  )}
                                </td>
                                <td>
                                  {convertFirstLetterToUpperCase(
                                    output.product_created_by
                                  )}
                                </td>
                                <td>
                                  {convertDateToHumanDate(
                                    output.product_updated_date
                                  )}
                                </td>

                                <td>
                                  {convertFirstLetterToUpperCase(
                                    output.product_updated_by
                                  )}
                                </td>

                                <td className="text-end">
                                  <div className="d-flex">
                                    <i
                                      onClick={() => handleOpenModal(output)}
                                      className="bi bi-eye small me-2 customIcon mx-3"
                                    ></i>
                                    <Link
                                      to={`/products/slider-images/${output.product_id}`}
                                    >
                                      <i className="bi bi-images small me-2 customIcon mx-3"></i>
                                    </Link>
                                    <Link
                                      to={`/products/variations/${output.product_id}`}
                                    >
                                      <i className="bi bi-basket small me-2 customIcon mx-3"></i>
                                    </Link>

                                    <Link
                                      target="_blank"
                                      to={`/products/${output.product_id}`}
                                    >
                                      <i className="bi bi-pencil small me-2 customIcon mx-3"></i>
                                    </Link>

                                    <i
                                      onClick={() =>
                                        handleDelete(output.product_id)
                                      }
                                      className="bi bi-trash small me-2 customIcon mx-3"
                                    ></i>
                                  </div>
                                </td>
                              </tr>
                            ))
                          : "No Records Found"}
                      </tbody>
                    </table>
                    {quickUpdateDetails.show &&
                      quickUpdateDetails.product !== null && (
                        <QuickUpdateProduct
                          show={quickUpdateDetails.show}
                          onClose={handleCloseModal}
                          id={quickUpdateDetails.product["product_id"]}
                          name={quickUpdateDetails.product["product_name"]}
                          max_price={
                            quickUpdateDetails.product["product_max_price"]
                          }
                          sales_price={
                            quickUpdateDetails.product["product_sale_price"]
                          }
                        />
                      )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default ListProduct;
