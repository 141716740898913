import { Link, useParams } from "react-router-dom";
import Footer from "../../Fragments/Footer";
import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import { Order } from "../../type/order";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../context/authContext";
import { cancelOrder } from "../../Services/orderService";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";
import { convertDateToHumanDate } from "../../helpers/functions";

function OrderDetails() {
  const [order, setOrder] = useState<Order | null>(null);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");

  const [selectedStatus, setSelectedStatus] = useState(1);
  const { ordersModule } = useContext(AuthContext);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [razorpayStatus, setRazorpayStatus] = useState<boolean>(false);
  const [razorpayMessage, setRazorpayMessage] = useState<boolean>(false);
  const [razorpayOrderId, setRazorpayOrderId] = useState<string>("");
  const [razorPaymentLoading, setRazorPaymentLoading] = useState<boolean>(true);

  async function handleRazorpayPayment() {
    setRazorpayStatus(true);

    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/orders/verification/razorpay/${razorpayOrderId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setRazorpayMessage(response.data.output);
        setRazorPaymentLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleChange = (event: any) => {
    setSelectedStatus(event.target.value);
  };

  const param = useParams<{ id: string }>();

  async function getOrder(order_id: string) {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/orders/${order_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(async (response) => {
        setOrder(response.data.output);
        setSelectedStatus(
          response.data.output.tracking_details.tracking_status_id
        );
        setRazorpayOrderId(response.data.output.order_details.transaction_id);
        if (razorpayOrderId.length > 0) {
          await handleRazorpayPayment();
        }
      })
      .catch((error) => {});
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/orders/update-status/${param.id}`,
        {
          status: Number(selectedStatus),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setSuccessMessage("Status Updated Successfully");
        if (param.id) {
          getOrder(param.id);
        }
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      })
      .catch((error) => {
        setError("Something went wrong");
        setTimeout(() => {
          setError("");
        }, 3000);
      });
  };

  async function handleCodStatus() {
    const orderId = param.id;
    if (order) {
      const status = order.order_details.cod_status === 1 ? 0 : 1;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/orders/update-cod-status/${orderId}/${status}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 200) {
        setSuccessMessage("Status Updated Successfully");
        if (param.id) {
          getOrder(param.id);
        }
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      }
    }
  }

  async function handleCancelOrder() {
    const order_id = Number(param.id);
    const response = await cancelOrder(order_id);
    if (response?.status === 200) {
      setSuccessMessage(response?.message);
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
      window.location.reload();
    } else {
      setError(response?.message);
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  }

  let statusClass = "";

  switch (order?.tracking_details?.tracking_status_title) {
    case "placed":
      statusClass = "badge bg-secondary";
      break;
    case "confirmed":
      statusClass = "badge bg-info";
      break;
    case "packed":
      statusClass = "badge bg-primary";
      break;
    case "out for delivery":
      statusClass = "badge bg-dark";
      break;
    case "delivered":
      statusClass = "badge bg-success";
      break;
    case "cancelled":
      statusClass = "badge bg-danger";
      break;
    case "refunded":
      statusClass = "badge bg-warning";
      break;
    default:
      statusClass = "badge bg-light text-dark";
  }

  async function handleDownloadImages() {
    setButtonLoading(true);
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/orders/order/printing/${param.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          const url = response.data.output;
          window.open(url);
          setButtonLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setButtonLoading(false);
        });
    } catch (error) {
      console.log(error);
      setButtonLoading(false);
    }
  }

  useEffect(() => {
    if (param.id) {
      getOrder(param.id);
    }
  }, [param.id, razorpayOrderId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {ordersModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content ">
            {razorpayStatus && (
              <div>
                {razorPaymentLoading === true ? (
                  <div className="alert alert-warning" role="alert">
                    <strong>Verifying Payment...</strong> Please Wait
                  </div>
                ) : (
                  <>
                    {razorpayMessage === true ? (
                      <div className="alert alert-success" role="alert">
                        <strong>
                          Payment Verified From Razorpay Side Successfully. You
                          can process this order
                        </strong>{" "}
                      </div>
                    ) : (
                      <div className="alert alert-danger" role="alert">
                        <>Payment Not Verified From Razorpay Side.</>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            <div className="mb-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">
                    Order Detail
                  </li>
                </ol>
              </nav>
            </div>
            {order && (
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="mb-5 d-flex align-items-center justify-content-between">
                        {new Date(
                          order?.order_details?.created_date
                        ).toDateString() === new Date().toDateString() && (
                          <button
                            onClick={handleCancelOrder}
                            className="btn btn-danger"
                          >
                            Cancel Order
                          </button>
                        )}
                        {order?.order_details.transaction_id &&
                          order?.order_details.transaction_id.length > 0 && (
                            <span>
                              Order ID{" "}
                              <Link to="#">
                                {order?.order_details.transaction_id}
                              </Link>
                            </span>
                          )}
                        <h3
                          style={{ fontSize: "14px" }}
                          className={statusClass}
                        >
                          {order?.tracking_details?.tracking_status_title}
                        </h3>
                      </div>
                      <div className="row mb-5 g-4">
                        <div className="col-md-3 col-sm-6">
                          <p className="fw-bold">Order Created at</p>
                          {convertDateToHumanDate(
                            order.order_details.created_date
                          )}
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <p className="fw-bold">Name</p>
                          {order.user_details?.user_first_name &&
                          order.user_details?.user_first_name.length > 0 ? (
                            <>
                              {order.user_details?.user_first_name}{" "}
                              {order.user_details?.user_last_name}
                            </>
                          ) : (
                            "No Name Found"
                          )}
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <p className="fw-bold">Email</p>
                          {order.user_details?.user_email &&
                          order.user_details?.user_email.length > 0
                            ? order.user_details?.user_email
                            : "No Email Found"}
                        </div>
                        <div className="col-md-3 col-sm-6">
                          <p className="fw-bold">Contact No</p>
                          {order.user_details?.user_mobile_number &&
                          order.user_details?.user_mobile_number.length > 0
                            ? order.user_details?.user_mobile_number
                            : "No Contact Number Found"}
                        </div>
                      </div>
                      <div className="row g-4">
                        <div className="col-md-6 col-sm-12">
                          <div className="card">
                            <div className="card-body d-flex flex-column gap-3">
                              <div className="d-flex justify-content-between">
                                <h5 className="mb-0">Delivery Address</h5>
                              </div>

                              <div>
                                Full Name :-
                                <strong>
                                  {order.address_details?.address_full_name}
                                </strong>
                              </div>
                              <div>
                                Address Details :-
                                <strong>
                                  {order.address_details?.address_address_one}
                                </strong>
                              </div>
                              <div>
                                Address Details :-
                                <strong>
                                  {order.address_details?.address_address_two}
                                </strong>
                              </div>
                              <div>
                                Land Mark :-
                                <strong>
                                  {order.address_details?.address_land_mark}
                                </strong>
                              </div>
                              <div>
                                City :-
                                <strong>
                                  {order.address_details?.address_city}
                                </strong>
                              </div>
                              <div>
                                Pin Code :-{" "}
                                <strong>
                                  {order.address_details?.address_pin_code}
                                </strong>
                              </div>
                              <div>
                                Phone Number :-
                                <strong>
                                  {order.address_details?.address_phone_number}
                                </strong>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <div className="card">
                            <div className="card-body d-flex flex-column gap-3">
                              <div className="d-flex justify-content-between">
                                <h5 className="mb-0">
                                  Order Details({" "}
                                  <strong
                                    style={{
                                      color: "red",
                                      fontSize: "1.2em",
                                    }}
                                  >
                                    {order.order_details.total}
                                  </strong>{" "}
                                  )
                                </h5>
                              </div>

                              <div>
                                Products Price :-{" "}
                                <strong>
                                  {order.order_details.total -
                                    order.order_details.tip -
                                    order.order_details.coupon -
                                    order.order_details.delivery_charges}
                                </strong>
                              </div>
                              <div>
                                Coupon :-
                                <strong>{order.order_details.coupon}</strong>
                              </div>

                              <div>
                                Delivery Charges{" "}
                                <strong>
                                  {order.order_details?.delivery_charges}
                                </strong>
                              </div>
                              {/* <div>
                                Tip :-{" "}
                                <strong>{order.order_details.tip}</strong>
                              </div>
                              <div>
                                Convenience Fee :- <strong>15</strong>
                              </div> */}
                              <div>
                                Total :-
                                <strong
                                  style={{ color: "red", fontSize: "1.2em" }}
                                >
                                  {order.order_details.total}
                                </strong>
                              </div>

                              {/* <div>
                                Delivery Type :-{" "}
                                <strong>
                                  {order.order_details.delivery_type}
                                </strong>
                              </div> */}
                              <div>
                                Payment Method :-
                                <strong>
                                  {order.order_details.payment_method}
                                </strong>
                              </div>
                              <div>
                                Transaction ID :-
                                <strong>
                                  {order.order_details.transaction_id &&
                                  order.order_details.transaction_id.length > 0
                                    ? order.order_details.transaction_id
                                    : "Wallet or COD Payment"}
                                </strong>
                              </div>
                              <div>
                                Tracking Number :-
                                <strong>
                                  {order.order_details.tracking_number}
                                </strong>
                              </div>
                              <div>
                                Notes :-
                                <strong>
                                  {order.order_details.notes &&
                                  order.order_details.notes.length > 0
                                    ? order.order_details.notes
                                    : "No Notes Found"}
                                </strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card widget">
                    <h5 className="card-header">Order Items</h5>
                    <div className="card-body">
                      <div
                        className="table-responsive"
                        style={{ overflow: "hidden", outline: "none" }}
                        tabIndex={1}
                      >
                        <table className="table table-custom mb-0">
                          <thead>
                            <tr>
                              <th>Photo</th>
                              <th>Name</th>
                              <th>Quantity</th>
                              <th>Min Price</th>
                              <th>Max Price</th>
                              <th>Total Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {order.product_details.map((product) => {
                              return (
                                <tr key={product.product_id}>
                                  <td>
                                    <img
                                      src={
                                        product.product_featured_image &&
                                        product.product_featured_image.length
                                          ? product.product_featured_image
                                          : "/assets/images/default.png"
                                      }
                                      className="rounded"
                                      width={60}
                                      alt="..."
                                    />
                                  </td>
                                  <td>{product.product_name}</td>
                                  <td>{product.product_quantity}</td>
                                  <td>{product.product_min_price}</td>
                                  <td>{product.product_price}</td>
                                  <td>
                                    {product.product_min_price *
                                      product.product_quantity}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 mt-4 mt-lg-0">
                  {order.order_details.payment_method ===
                    "CASH_ON_DELIVERY" && (
                    <div className="card">
                      <div className="card-body">
                        <h6 className="card-title mb-4">COD Payment Status</h6>
                        <div className="row justify-content-center mb-3">
                          <div className="col-6 text-end">Payment Status </div>
                          <div className="col-6">
                            {order.order_details.cod_status === 1
                              ? "Paid"
                              : "Unpaid"}
                          </div>
                        </div>

                        <div className="text-center mt-4">
                          <button
                            className="btn btn-outline-primary"
                            onClick={handleCodStatus}
                          >
                            Update Status to{" "}
                            {order.order_details.cod_status === 0
                              ? "Paid"
                              : "Unpaid"}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {order.print_images && order.print_images.length > 0 && (
                    <div className="card my-5">
                      <div className="card-body">
                        {successMessage && (
                          <div className="alert alert-success" role="alert">
                            {successMessage}
                          </div>
                        )}
                        {errorMessage && (
                          <div className="alert alert-danger" role="alert">
                            {errorMessage}
                          </div>
                        )}
                        <p>
                          <strong>Note:-</strong> The user has uploaded images
                          for print. You can download the images from here.
                        </p>
                        <h6 className="card-title mb-4">Download Images</h6>
                        <div className="text-center mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={handleDownloadImages}
                            disabled={buttonLoading}
                          >
                            {buttonLoading
                              ? "Donwloading..."
                              : "Download Images"}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="card my-5">
                    <div className="card-body">
                      {successMessage && (
                        <div className="alert alert-success" role="alert">
                          {successMessage}
                        </div>
                      )}
                      {errorMessage && (
                        <div className="alert alert-danger" role="alert">
                          {errorMessage}
                        </div>
                      )}
                      <p>
                        <strong>Note:-</strong> The moment you change the
                        status, the customer will be notified via email and SMS.
                        Please make sure you have the correct status selected.
                      </p>
                      <h6 className="card-title mb-4">Order Status</h6>
                      <form onSubmit={handleSubmit}>
                        <div className="row justify-content-center mb-3">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            value={selectedStatus}
                            onChange={handleChange}
                          >
                            <option className="form-select-lg" value={2}>
                              Placed
                            </option>
                            <option className="form-select-lg" value={3}>
                              Confirmed
                            </option>
                            <option className="form-select-lg" value={4}>
                              Packed
                            </option>
                            <option className="form-select-lg" value={5}>
                              Out for delivery
                            </option>
                            <option className="form-select-lg" value={6}>
                              Delivered
                            </option>
                            <option className="form-select-lg" value={7}>
                              Cancelled
                            </option>
                            <option className="form-select-lg" value={8}>
                              Refunded
                            </option>
                          </select>
                        </div>

                        <div className="text-center mt-4">
                          <button type="submit" className="btn btn-primary">
                            Update
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default OrderDetails;
