import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { AuthContext } from "../context/authContext";
import axios from "axios";
import { io } from "socket.io-client";

function Header() {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const socket = io(`https://mcl.api.wallayl.com`);

    socket.on("connect", () => {});
    socket.on("notification-count", async (data) => {
      await getNotifications();
    });
    socket.on("notification", async (data: any) => {
      new Notification("New Notification", {
        body: data,
      });
      await getNotifications();
    });

    return () => {
      socket.disconnect();
    };
  }, [authContext]); // eslint-disable-line react-hooks/exhaustive-deps

  async function getNotifications() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/notifications/all/admin`, {
        headers: {
          Authorization: `Bearer ${new Cookies().get("token")}`,
        },
      })
      .then((res) => {
        authContext.setNotificationCount(res.data.output.unreadCount);
      });
  }

  useEffect(() => {
    getNotifications();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const cookies = new Cookies();

  function logout() {
    localStorage.removeItem("token");
    cookies.remove("token", { path: "/" });
    window.location.href = "/";
  }

  function showNotifications() {
    authContext.setNotificationSidebar(true);
  }

  return (
    <>
      <div className="header">
        <div className="menu-toggle-btn">
          <Link to="#">
            <i className="bi bi-list"></i>
          </Link>
        </div>

        <Link to="index.html" className="logo">
          <img width="100" src="assets/images/mcl.png" alt="logo" />
        </Link>

        <div className="header-bar ms-auto">
          <ul className="navbar-nav justify-content-end">
            <li className="nav-item">
              {authContext.notificationCount > 0 ? (
                <Link
                  to=""
                  onClick={showNotifications}
                  className="nav-link nav-link-notify"
                  data-count={
                    authContext.notificationCount > 0
                      ? authContext.notificationCount
                      : "No New Notifications"
                  }
                >
                  <i className="bi bi-bell icon-lg"></i>
                </Link>
              ) : (
                <Link to="" onClick={showNotifications}>
                  <i className="bi bi-bell icon-lg"></i>
                </Link>
              )}
            </li>
            <li className="nav-item ms-3">
              <button onClick={logout} className="btn btn-primary btn-icon">
                Logout
              </button>
            </li>
          </ul>
        </div>

        <div className="header-mobile-buttons">
          <Link to="#" className="search-bar-btn">
            <i className="bi bi-search"></i>
          </Link>
          <Link to="#" className="actions-btn">
            <i className="bi bi-three-dots"></i>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Header;
