import {
  ProductFormValues,
  QuickUpdateProductFormValues,
  TotalCount,
  UpdateProductFormValues,
} from "../type/product";
import { ProductResponse } from "../type/product";

import axios, { AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// LIST PRODUCT
export default async function getListOfProducts(
  pageNumber: number = 1,
  pageSize: number = 25
) {
  const response: AxiosResponse<ProductResponse> = await axios.get(
    `${API_URL}/products?page=${pageNumber}&pageSize=${pageSize}`,
    { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
  );
  return response.data;
}

export async function getQueryProducts(query: string) {
  const response: AxiosResponse<ProductResponse> = await axios.get(
    `${API_URL}/products/search/${query}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return response.data.output;
}

export async function getSingleProduct(id: number) {
  const response = await axios.get(`${API_URL}/products/${id}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return response.data.output[0];
}

// COUNT PRODUCT
export async function getCountOfProducts(): Promise<TotalCount> {
  const response: AxiosResponse<TotalCount> = await axios.get(
    `${API_URL}/products/total/count`,
    { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
  );
  return response.data;
}

// API FOR ADD PRODUCT
export async function addProduct(
  product: ProductFormValues
): Promise<AxiosResponseIF> {
  try {
    const formData = new FormData();
    formData.append("name", product.name);
    formData.append("min_price", product.min_price.toString());
    formData.append("max_price", product.max_price.toString());
    formData.append("sales_price", product.sales_price.toString());
    formData.append("best_seller", product.best_seller.toString());
    Array.from(product.category).forEach((category) => {
      formData.append("category", category.toString());
    });
    formData.append("coupon", product.coupon.toString());
    formData.append("discount", product.discount.toString());
    formData.append("featured", product.featured.toString());
    formData.append("hot_deals", product.hot_deals.toString());
    formData.append("stock_quantity", product.stock_quantity.toString());
    formData.append("popular", product.popular.toString());
    formData.append("tax", product.tax.toString());
    formData.append("images", product.images as any);
    formData.append("description", product.description);
    formData.append("code", product.code);
    formData.append("whole_sell_price", product.whole_sell_price.toString());

    return await axios
      .post(`${API_URL}/products`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch((err) => {
        return {
          status: err.response?.status || 500,
          data: err.response?.data || "Network error",
        };
      });
  } catch (error) {
    console.error(error);
    throw new Error("Failed to add product");
  }
}

export async function getLimitedProduct(limit: string) {
  const response = await axios.get(
    `${API_URL}/parentcategories?limit=${limit}`,
    { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
  );
  return response.data.output;
}

export async function getProductByFilterName(filter: string) {
  const response = await axios.get(`${API_URL}/products/filters/${filter}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
  return response.data.output;
}
interface AxiosResponseIF {
  status: number;
  data: any;
}
export async function updateProduct(
  id: number,
  data: UpdateProductFormValues
): Promise<AxiosResponseIF> {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  try {
    const response = await axios.put(`${API_URL}/products/${id}`, data, {
      headers,
    });
    return {
      status: response.status,
      data: response.data,
    };
  } catch (err: any) {
    return {
      status: err.response?.status || 500,
      data: err.response?.data || "Network error",
    };
  }
}

export async function quickUpdateProduct(
  id: number,
  data: QuickUpdateProductFormValues
): Promise<AxiosResponseIF> {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  try {
    const response = await axios.put(
      `${API_URL}/products/quick-update/${id}`,
      data,
      {
        headers,
      }
    );
    return {
      status: response.status,
      data: response.data,
    };
  } catch (err: any) {
    return {
      status: err.response?.status || 500,
      data: err.response?.data || "Network error",
    };
  }
}

export async function deleteProduct(id: number) {
  try {
    const response = await axios.delete(`${API_URL}/products/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw new Error("Failed to delete parent category");
  }
}

export async function deleteProductVariation(id: number) {
  try {
    const response = await axios.delete(
      `${API_URL}/products/delete/variation/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw new Error("Failed to delete parent category");
  }
}

export async function uploadImage(productId: number, imageFile: File) {
  try {
    const formData = new FormData();
    formData.append("image", imageFile);

    const response = await axios.post(
      `${API_URL}/products/upload-image/${productId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
}
export async function uploadImages(productId: number, imageFile: any) {
  try {
    const formData = new FormData();
    for (let i = 0; i < imageFile.length; i++) {
      formData.append("images", imageFile[i]);
    }
    const response = await axios.post(
      `${API_URL}/products/upload-images/${productId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function getListOfProductVariants(id: number) {
  const response = await axios.get(`${API_URL}/products/get/variations/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return response.data.output;
}

export async function addVariation(
  productId: number,
  name: string,
  price: number,
  max_price: number
) {
  try {
    const data = {
      product_id: productId,
      varation_name: name,
      price: price,
      max_price: max_price,
    };

    const response = await axios.post(
      `${API_URL}/products/add/variations`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function updateVariation(
  productId: number,
  name: string,
  price: number,
  max_price: number
) {
  try {
    const data = {
      variation_id: productId,
      varation_name: name,
      price: price,
      max_price: max_price,
    };

    const response = await axios.patch(
      `${API_URL}/products/update/variations`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getListOfProductCategories(productId: number) {
  try {
    const response = await axios.get(
      `${API_URL}/products/list-of-categories/${productId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return response.data.output;
  } catch (error) {
    console.error(error);
  }
}

export async function updateProductPrintStatus(
  productId: number,
  checked: boolean
) {
  try {
    return await axios
      .patch(
        `${API_URL}/products/update-printable/${productId}`,
        {
          is_printable: checked === true ? 1 : 0,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        return {
          status: response.status,
          data: response.data,
        };
      })
      .catch((err) => {
        return {
          status: err.response?.status || 500,
          data: err.response?.data || "Network error",
        };
      });
  } catch (error) {
    console.error(error);
  }
}
