import axios from "axios";
import { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";
import Cookies from "universal-cookie";

function MemoryUsage() {
  const [memoryUsage, setMemoryUsage] = useState(0);

  useEffect(() => {
    const fetchMemoryUsage = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/bucket/size`,
          {
            headers: {
              Authorization: `Bearer ${new Cookies().get("token")}`,
            },
          }
        );
        const totalDisk = 3125000000;
        const usedDisk = response.data.size;
        const percent = (usedDisk / totalDisk) * 100;
        setMemoryUsage(percent);
      } catch (err) {
        console.error("Error fetching Memory usage:", err);
        setMemoryUsage(0);
      }
    };

    fetchMemoryUsage();
  }, []);
  return (
    <GaugeChart
      id="gauge-chart3"
      nrOfLevels={30}
      colors={["#FF5F6D", "#FFC371"]}
      arcWidth={0.3}
      percent={memoryUsage}
    />
  );
}

export default MemoryUsage;
