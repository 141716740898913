import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import Footer from "../../Fragments/Footer";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";

import { UpdateProductFormValues } from "../../type/product";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import {
  getListOfProductCategories,
  getSingleProduct,
  updateProduct,
  updateProductPrintStatus,
  uploadImage,
  uploadImages,
} from "../../Services/productService";
import { AuthContext } from "../../context/authContext";
import Multiselect from "multiselect-react-dropdown";
import { CategoryPopulate } from "../../type/category";
import { populateCategories } from "../../Services/categoryService";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  code: Yup.string().required("Code is required"),
  min_price: Yup.number()
    .min(0, "Minimum price must be a positive number")
    .required("Minimum price is required"),
  max_price: Yup.number()
    .min(
      Yup.ref("min_price"),
      "Maximum price must be greater than or equal to minimum price"
    )
    .required("Maximum price is required"),
  sales_price: Yup.number()
    .min(0, "Sales price must be a positive number")
    .required("Sales price is required"),
  whole_sell_price: Yup.number()
    .min(0, "Whole sell price must be a positive number")
    .required("Whole sell price is required"),
  best_seller: Yup.number()
    .oneOf([0, 1], "Best seller value must be either 0 or 1")
    .required("Best seller value is required"),
  hot_deals: Yup.number()
    .oneOf([0, 1], "Hot deals value must be either 0 or 1")
    .required("Hot deals value is required"),
  featured: Yup.number()
    .oneOf([0, 1], "Featured value must be either 0 or 1")
    .required("Featured value is required"),
  popular: Yup.number()
    .oneOf([0, 1], "Popular value must be either 0 or 1")
    .required("Popular value is required"),
  stock_quantity: Yup.number()
    .min(0, "Stock quantity must be a positive number")
    .required("Stock quantity is required"),
  category: Yup.number().min(0, "Category must be a positive number"),
});

function UpdateProduct() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [category, setCategory] = useState<CategoryPopulate[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<CategoryPopulate[]>(
    []
  );

  const [bestSeller, setBestSeller] = useState(0);
  const [hotDeals, setHotDeals] = useState(0);
  const [featured, setFeatured] = useState(0);
  const [popular, setPopular] = useState(0);
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { productsModule } = useContext(AuthContext);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState(null);
  const param = useParams();
  const productId = Number(param.id);

  const handleCheckboxChange = (event: any) => {
    const checkboxName = event.target.name;
    const isChecked = event.target.checked;

    switch (checkboxName) {
      case "best_seller":
        setBestSeller(isChecked === true ? 1 : 0);

        break;
      case "hot_deals":
        setHotDeals(isChecked === true ? 1 : 0);
        break;
      case "featured":
        setFeatured(isChecked === true ? 1 : 0);
        break;
      case "popular":
        setPopular(isChecked === true ? 1 : 0);
        break;
      default:
        break;
    }
  };

  async function init() {
    const response = await populateCategories();

    if (response.length > 0) {
      setCategory(response);
    } else {
      setCategory([]);
    }
  }

  async function handleSelectChange() {
    const response = await populateCategories();

    if (response.length > 0) {
      setCategory(response);
    } else {
      setCategory([]);
    }
  }

  const handleimageSet = (event: any) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleimagesSet = (event: any) => {
    setSelectedImages(event.target.files);
  };

  const handleImageSubmit = async (event: any) => {
    event.preventDefault();

    if (selectedImage) {
      try {
        setLoading(true);
        await uploadImage(productId, selectedImage);

        setSuccessMessage("Image uploaded successfully.");
        setSelectedImage(null);
        setLoading(false);
      } catch (error) {
        setError("Image upload failed. Please try again.");
        console.error(error);
      }
    } else {
      setError("Please select an image to upload.");
    }
  };

  const handleImagesSubmit = async (event: any) => {
    event.preventDefault();

    if (selectedImages) {
      try {
        setLoading(true);
        await uploadImages(productId, selectedImages);

        setSuccessMessage("Image uploaded successfully.");
        setSelectedImages(null);
        setLoading(false);
      } catch (error) {
        setError("Image upload failed. Please try again.");
        console.error(error);
      }
    } else {
      setError("Please select an image to upload.");
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files?.[0];
    if (file) {
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const [initialValues, setInitialValues] = useState<UpdateProductFormValues>({
    name: "",
    code: "",
    min_price: 0,
    max_price: 0,
    sales_price: 0,
    whole_sell_price: 0,
    best_seller: bestSeller,
    hot_deals: hotDeals,
    featured: featured,
    popular: popular,
    stock_quantity: 0,
    category: [],
    active: 0,
    description: "",
    is_printable: 0,
  });

  async function getProduct(productId: number) {
    const response = await getSingleProduct(productId);
    const formattedData: UpdateProductFormValues = {
      name: response.product_name,
      code: response.product_code,
      min_price: response.product_min_price,
      max_price: response.product_max_price,
      whole_sell_price: response.product_whole_sale_price,
      active: response.product_active,
      featured: Number(response.featured),
      sales_price: response.product_sale_price,
      best_seller: Number(response.best_seller),
      hot_deals: Number(response.hot_deals),
      popular: Number(response.popular),
      stock_quantity: response.stock_quantity,
      category: response.categoryId,
      description: response.product_description,
      is_printable: response.product_is_printable,
    };
    setBestSeller(Number(response.best_seller));
    setHotDeals(Number(response.hot_deals));
    setFeatured(Number(response.featured));
    setPopular(Number(response.popular));

    setInitialValues(formattedData);
    setImageUrl(response.product_featured_image);
  }

  async function getSelectedCategory(productId: number) {
    const response = await getListOfProductCategories(productId);
    setSelectedCategory(response);
  }

  interface AxiosResponseIF {
    status: number;
    data: any;
  }

  const handleSubmit = async (values: UpdateProductFormValues) => {
    try {
      values.category = selectedCategory.map(
        (item: CategoryPopulate) => item.category_id
      );

      values.best_seller = bestSeller;
      values.hot_deals = hotDeals;
      values.featured = featured;
      values.popular = popular;
      values.max_price = Number(values.max_price);
      values.min_price = Number(values.min_price);
      values.sales_price = Number(values.sales_price);
      values.active = Number(values.active);

      const response: AxiosResponseIF = await updateProduct(productId, values);
      if (response.status === 400) {
        setError(response.data.message);
        setTimeout(() => {
          setError("");
        }, 2000);
        return;
      }
      if (response) {
        setSuccessMessage("Updated Successfully");
        window.scrollTo(0, 0);
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        // window.location.reload();
      } else {
        setError("An error occurred");
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    } catch (error) {
      setError("An error Occurred");
    }
  };

  async function handlePrintableProduct(e: any) {
    try {
      console.log(e.target.checked);
      const response = await updateProductPrintStatus(
        productId,
        e.target.checked
      );
      if (response?.status === 200) {
        setSuccessMessage("Updated Successfully");
        window.location.reload();
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
      }
    } catch (error) {
      setError("An error Occurred");
    }
  }

  useEffect(() => {
    getProduct(productId);
    getSelectedCategory(productId);
    init();
  }, [productId]);

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {productsModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content ">
            <div className="mb-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="bi bi-globe2 small me-2" /> Dashboard
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div
                  id="checkout-form-wizard"
                  role="application"
                  className="wizard clearfix"
                >
                  <div className="content clearfix">
                    <div className="row g-4 mb-3">
                      <div className="col-md-6">
                        <div className="my-2">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                              checked={initialValues.is_printable === 1}
                              onClick={(e) => handlePrintableProduct(e)}
                            />
                            <label className="form-check-label">
                              The Product can be printed
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3
                      id="checkout-form-wizard-h-0"
                      tabIndex={-1}
                      className="title current"
                    >
                      Update Product
                    </h3>
                    {successMessage !== "" ? (
                      <div className="alert alert-success" role="alert">
                        {successMessage}
                      </div>
                    ) : (
                      ""
                    )}
                    {errorMessage !== "" ? (
                      <div className="alert alert-danger" role="alert">
                        {errorMessage}
                      </div>
                    ) : (
                      ""
                    )}
                    {initialValues.name.length > 3 ? (
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                      >
                        {({ values, setFieldValue }) => (
                          <Form
                            onKeyDown={(e) =>
                              e.key === "Enter" && e.preventDefault()
                            }
                          >
                            <div className="row g-4 mb-3">
                              <div className="col-md-6">
                                <div className="my-2">
                                  <label className="pb-3"> Code</label>
                                  <Field
                                    className="form-control"
                                    placeholder="Enter Product Code"
                                    type="text"
                                    name="code"
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="code" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="my-2">
                                  <label className="pb-3"> Name</label>
                                  <Field
                                    className="form-control"
                                    placeholder="Enter Product Name"
                                    type="text"
                                    name="name"
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="name" />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row g-4 mb-3">
                              <div className="col-md-3">
                                <div className="my-2">
                                  <label className="pb-3">Sales Price</label>
                                  <Field
                                    className="form-control"
                                    placeholder=" Enter min price "
                                    type="number"
                                    name="min_price"
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="min_price" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="my-2">
                                  <label className="pb-3">MRP</label>
                                  <Field
                                    className="form-control"
                                    placeholder=" Enter max price"
                                    type="number"
                                    name="max_price"
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="max_price" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="my-2">
                                  <label className="pb-3">B Price</label>
                                  <Field
                                    className="form-control"
                                    placeholder="Enter Wholesell Price"
                                    type="number"
                                    name="sales_price"
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="sales_price" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="my-2">
                                  <label className="pb-3">C Price</label>
                                  <Field
                                    className="form-control"
                                    placeholder="  Whole sell price"
                                    type="number"
                                    name="whole_sell_price"
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="whole_sell_price" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row g-4 mb-3">
                              <div className="col-md-6">
                                {" "}
                                <div className="my-2">
                                  <label className="pb-3"> Active</label>
                                  <Field
                                    className="form-control"
                                    placeholder=" Select one option"
                                    type="date"
                                    name="active"
                                    as="select"
                                  >
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                  </Field>

                                  <div style={{ color: "red" }}>
                                    <ErrorMessage name="active" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="my-2">
                                  <label className="pb-3">
                                    Stock Availability
                                  </label>
                                  <Field
                                    as="select"
                                    className="form-control"
                                    defaultValue={
                                      values.stock_quantity === 0 ? "no" : "yes"
                                    }
                                    name="isStockAvailable"
                                    onChange={(e: any) => {
                                      if (e.target.value === "yes") {
                                        setFieldValue("stock_quantity", 1);
                                      } else {
                                        setFieldValue("stock_quantity", 0);
                                      }
                                    }}
                                  >
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                  </Field>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="my-2">
                                <label className="pb-3"> Category</label>
                                <Multiselect
                                  className="form-control"
                                  options={category}
                                  selectedValues={selectedCategory}
                                  displayValue="category_name"
                                  selectionLimit={10}
                                  onSearch={handleSelectChange}
                                  onSelect={(selectedList) => {
                                    const selectedCategories = selectedList.map(
                                      (item: any) => item.category_id
                                    );
                                    setSelectedCategory(selectedList);
                                    setFieldValue(
                                      "category",
                                      selectedCategories
                                    );
                                  }}
                                  onRemove={(selectedList) => {
                                    const selectedCategories = selectedList.map(
                                      (item: any) => item.category_id
                                    );
                                    setSelectedCategory(selectedList);
                                    setFieldValue(
                                      "category",
                                      selectedCategories
                                    );
                                  }}
                                />
                                <div style={{ color: "red" }}>
                                  <ErrorMessage name="category" />
                                </div>
                              </div>
                            </div>

                            <div className="row g-4 mb-3">
                              <div className="row g-4 mb-3">
                                <div className="col-md-3">
                                  <div className="my-3">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        checked={
                                          bestSeller === 1 ? true : false
                                        }
                                        id="best_seller"
                                        name="best_seller"
                                        onChange={handleCheckboxChange}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="best_seller"
                                      >
                                        Best Seller
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="my-3">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="hot_deals"
                                        name="hot_deals"
                                        checked={hotDeals === 1 ? true : false}
                                        onChange={handleCheckboxChange}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="hot_deals"
                                      >
                                        Hot Deals
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="my-2">
                                    <div className="my-3">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="featured"
                                          name="featured"
                                          onChange={handleCheckboxChange}
                                          checked={
                                            featured === 1 ? true : false
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="featured"
                                        >
                                          Featured
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="my-2">
                                    <div className="my-3">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="popular"
                                          name="popular"
                                          onChange={handleCheckboxChange}
                                          checked={popular === 1 ? true : false}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="popular"
                                        >
                                          Popular
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="my-2">
                                    <label className="pb-3"> Description</label>
                                    <textarea
                                      cols={50}
                                      rows={5}
                                      className="form-control"
                                      placeholder="Description"
                                      name="description"
                                      value={values.description}
                                      onChange={(e) =>
                                        setFieldValue(
                                          "description",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <div style={{ color: "red" }}>
                                      <ErrorMessage name="description" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex justify-content-center my-3">
                              <button type="submit" className="btn btn-primary">
                                Update Product
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  id="checkout-form-wizard"
                  role="application"
                  className="wizard clearfix"
                >
                  <div className="content clearfix">
                    {initialValues.name.length > 0 && (
                      <form onSubmit={handleImageSubmit}>
                        <div className="row g-4 mb-3">
                          <div className="col-md-12">
                            <div className="d-flex justify-content-center my-2">
                              {imagePreview ? (
                                <img
                                  className="mt-5 border-5"
                                  src={imagePreview}
                                  alt="Preview"
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "300px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    imageUrl !== null && imageUrl.length > 0
                                      ? imageUrl
                                      : "/assets/images/default.png"
                                  }
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "300px",
                                  }}
                                  alt={initialValues.name}
                                />
                              )}
                            </div>
                          </div>

                          <h5>Featured Images</h5>
                          <div className="col-md-12">
                            <div className="my-2">
                              <input
                                type="file"
                                name="image"
                                accept="image/*"
                                className="form-control"
                                onChange={(event) => {
                                  handleImageChange(event);
                                  handleimageSet(event);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center my-3">
                          <button
                            disabled={loading}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Update Featured Image
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
                <br />
                <br />
                <br />
                <div
                  id="checkout-form-wizard"
                  role="application"
                  className="wizard clearfix"
                >
                  <div className="content clearfix">
                    {initialValues.name.length > 0 && (
                      <form onSubmit={handleImagesSubmit}>
                        <div className="row g-4 mb-3">
                          <div className="col-md-12">
                            <div className="my-2">
                              <input
                                type="file"
                                name="image"
                                accept="image/*"
                                multiple
                                className="form-control"
                                onChange={(event) => {
                                  handleimagesSet(event);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center my-3">
                          <button
                            disabled={loading}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Update Slider Images
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default UpdateProduct;
