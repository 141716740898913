import { Link } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { ProfileResponse } from "../type/admin";
import { useContext, useEffect, useState } from "react";
import { getAdminDetails } from "../Services/adminService";
import Loader from "./Loader";
import { AuthContext } from "../context/authContext";

function Layout() {
  const [userDetails, setUserDetails] = useState<ProfileResponse>();
  const [loader, setLoader] = useState(false);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    setLoader(true);
    const getUserDetails = async () => {
      const response = await getAdminDetails();
      setUserDetails(response);
      setLoader(false);
    };
    getUserDetails();
  }, []);
  return (
    <>
      <Sidebar>
        <Menu>
          <div className="menu">
            <div className="menu-header">
              <Link to="/dashboard" className="menu-header-logo">
                <img
                  src={`${process.env.REACT_APP_URL}/assets/images/mcl.png`}
                  alt="logo"
                />
              </Link>
              <Link to="/dashboard" className="btn btn-sm menu-close-btn">
                <i className="bi bi-x"></i>
              </Link>
            </div>
            <div
              className="menu-body"
              style={{ overflow: "hidden", outline: "none" }}
              tabIndex={2}
            >
              {loader ? (
                <Loader />
              ) : (
                <div className="dropdown">
                  <Link
                    to="#"
                    className="d-flex align-items-center"
                    data-bs-toggle="dropdown"
                  >
                    <div className="avatar me-3">
                      <img
                        src={
                          userDetails?.output.admin_photo !== null &&
                          userDetails?.output.admin_photo !== undefined &&
                          userDetails?.output.admin_photo !== "" &&
                          String(userDetails?.output.admin_photo).length > 5
                            ? userDetails?.output.admin_photo
                            : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg"
                        }
                        className="rounded-circle"
                        alt="img"
                      />
                    </div>
                    <div>
                      <div className="fw-bold">
                        {userDetails?.output.admin_first_name +
                          " " +
                          userDetails?.output.admin_last_name}
                      </div>
                      <small className="text-muted">
                        {userDetails?.output.admin_email}
                      </small>
                    </div>
                  </Link>
                </div>
              )}
              <div className="menu-body-scrollable">
                <MenuItem component={<Link to="/dashboard" />}>
                  Dashboard
                </MenuItem>
                {authContext.categoriesModule && (
                  <SubMenu className="active" label="Parent Categories">
                    <MenuItem component={<Link to="/parent-categories" />}>
                      List Parent Categories{" "}
                    </MenuItem>
                    <MenuItem component={<Link to="/parent-categories/add" />}>
                      Add Parent Categories{" "}
                    </MenuItem>
                  </SubMenu>
                )}

                {authContext.categoriesModule && (
                  <SubMenu className="active" label="Categories">
                    <MenuItem component={<Link to="/categories" />}>
                      List Categories{" "}
                    </MenuItem>
                    <MenuItem component={<Link to="/categories/add" />}>
                      Add Categories{" "}
                    </MenuItem>
                  </SubMenu>
                )}

                {authContext.productsModule && (
                  <SubMenu className="active" label="Products">
                    <MenuItem component={<Link to="/products" />}>
                      List Products{" "}
                    </MenuItem>
                    <MenuItem component={<Link to="/products/add" />}>
                      Add Products{" "}
                    </MenuItem>
                  </SubMenu>
                )}
                {authContext.couponModule && (
                  <SubMenu className="active" label="Coupons">
                    <MenuItem component={<Link to="/coupon" />}>
                      List Coupons{" "}
                    </MenuItem>
                    <MenuItem component={<Link to="/coupon/add" />}>
                      Add Coupon{" "}
                    </MenuItem>
                  </SubMenu>
                )}

                {authContext.ordersModule && (
                  <>
                    <SubMenu className="active" label="Order">
                      <MenuItem component={<Link to="/order" />}>
                        List Order{" "}
                      </MenuItem>
                    </SubMenu>
                  </>
                )}
                {authContext.usersModule && (
                  <SubMenu className="active" label="Customer">
                    <MenuItem component={<Link to="/customer" />}>
                      List Customer{" "}
                    </MenuItem>
                  </SubMenu>
                )}

                <SubMenu className="active" label="Notifications">
                  <MenuItem component={<Link to="/notification" />}>
                    List Notification{" "}
                  </MenuItem>
                  <MenuItem component={<Link to="/notification/add" />}>
                    Add Notification{" "}
                  </MenuItem>
                </SubMenu>
                {authContext.dealsModule && (
                  <SubMenu className="active" label="Banners">
                    <MenuItem component={<Link to="/banners" />}>
                      List Banners
                    </MenuItem>
                    <MenuItem component={<Link to="/banners/add" />}>
                      Add Banners
                    </MenuItem>
                  </SubMenu>
                )}

                {authContext.adminModules && (
                  <SubMenu className="active" label="Users">
                    <MenuItem component={<Link to="/admin" />}>
                      List Users
                    </MenuItem>
                    <MenuItem component={<Link to="/admin/add" />}>
                      Add User
                    </MenuItem>
                  </SubMenu>
                )}

                <MenuItem component={<Link to="/updateprofile" />}>
                  Update Profile{" "}
                </MenuItem>
                <MenuItem component={<Link to="/changepassword" />}>
                  Change Password{" "}
                </MenuItem>
                <MenuItem component={<Link to="/dashboard/developer" />}>
                  Developer Dashboard
                </MenuItem>
                <MenuItem component={<Link to="/customer-support" />}>
                  Customer Support
                </MenuItem>
                <MenuItem component={<Link to="/payment" />}>Billing</MenuItem>
              </div>
            </div>
          </div>
        </Menu>
      </Sidebar>
    </>
  );
}

export default Layout;
