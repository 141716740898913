import Header from "../../Fragments/Header";
import Layout from "../../Fragments/Layout";
import Footer from "../../Fragments/Footer";
import { Field, Formik, Form, FormikHelpers, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { ProductFormValues } from "../../../src/type/product";
import { addProduct, uploadImages } from "../../Services/productService";
import { CategoryPopulate } from "../../type/category";
import Multiselect from "multiselect-react-dropdown";
import { populateCategories } from "../../Services/categoryService";
import { AuthContext } from "../../context/authContext";
import AccessDeniedWithLoader from "../../Pages/AccessDenied";

const initialValues: ProductFormValues = {
  name: "",
  code: "",
  min_price: 0,
  max_price: 0,
  sales_price: 0,
  best_seller: 0,
  hot_deals: 0,
  featured: 0,
  whole_sell_price: 0,
  popular: 0,
  stock_quantity: 0,
  category: [],
  coupon: 0,
  discount: 0,
  tax: 0,
  images: "",
  description: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters long")
    .max(100, "Name must be less than 100 characters long"),
  code: Yup.string()
    .required("Code is required")
    .min(2, "Code must be at least 2 characters long")
    .max(20, "Code must be less than 50 characters long"),
  min_price: Yup.number()
    .min(0, "Minimum price must be a positive number")
    .max(99999, "Minimum price must be less than 99999")
    .required("Minimum price is required"),
  max_price: Yup.number()
    .min(
      Yup.ref("min_price"),
      "Maximum price must be greater than or equal to minimum price"
    )
    .max(99999, "Maximum price must be less than 99999")
    .required("Maximum price is required"),
  sales_price: Yup.number()
    .min(0, "Sales price must be a positive number")
    .max(99999, "Sales price must be less than 99999")
    .required("Sales price is required"),
  whole_sell_price: Yup.number()
    .min(0, "Whole Sell Price must be a positive number")
    .max(99999, "Whole Sell Price must be less than 99999")
    .required("Whole Sell Price is required"),
  best_seller: Yup.number()
    .oneOf([0, 1], "Best seller value must be either 0 or 1")
    .required("Best seller value is required"),
  category: Yup.array()
    .of(
      Yup.number()
        .min(0, "Each category must be a positive number")
        .max(1000, "Category must be less than 1000")
    )
    .required("Category is required"),
  coupon: Yup.number()
    .min(0, "Coupon must be a positive number")
    .max(1000, "Coupon must be less than 1000")
    .required("Coupon is required"),
  discount: Yup.number()
    .min(0, "Discount must be a positive number")
    .max(100, "Discount must be less than 100")
    .required("Discount is required"),
  featured: Yup.number()
    .oneOf([0, 1], "Featured value must be either 0 or 1")
    .required("Featured value is required"),
  hot_deals: Yup.number()
    .oneOf([0, 1], "Hot deals value must be either 0 or 1")
    .required("Hot deals value is required"),
  stock_quantity: Yup.number()
    .min(0, "Stock quantity must be a positive number")
    .max(10000, "Stock quantity must be less than 10000")
    .required("Stock quantity is required"),
  popular: Yup.number()
    .oneOf([0, 1], "Popular value must be either 0 or 1")
    .required("Popular value is required"),
  tax: Yup.number()
    .min(0, "Tax must be a positive number")
    .max(100, "Tax must be less than 100")
    .required("Tax is required"),
  description: Yup.string()
    .max(250, "Description must be less than 250 characters")
    .min(20, "Description must be at least 20 characters long"),
  // Skipping images as per request
});

function AddProduct() {
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [category, setCategory] = useState<CategoryPopulate[]>([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [featuredImage, setFeaturedImage] = useState<any>("");
  const { productsModule } = useContext(AuthContext);

  const [bestSeller, setBestSeller] = useState(0);
  const [hotDeals, setHotDeals] = useState(0);
  const [featured, setFeatured] = useState(0);
  const [popular, setPopular] = useState(0);

  const handleCheckboxChange = (event: any) => {
    const checkboxName = event.target.name;
    const isChecked = event.target.checked;

    switch (checkboxName) {
      case "best_seller":
        setBestSeller(isChecked ? 1 : 0);
        break;
      case "hot_deals":
        setHotDeals(isChecked ? 1 : 0);
        break;
      case "featured":
        setFeatured(isChecked ? 1 : 0);
        break;
      case "popular":
        setPopular(isChecked ? 1 : 0);
        break;
      default:
        break;
    }
  };

  async function handleSelectChange() {
    const response = await populateCategories();

    if (response.length > 0) {
      setCategory(response);
    } else {
      setCategory([]);
    }
  }

  async function init() {
    const response = await populateCategories();

    if (response.length > 0) {
      setCategory(response);
    } else {
      setCategory([]);
    }
  }

  useEffect(() => {
    init();
  }, []);

  const handleSubmit = async (
    values: ProductFormValues,
    formikHelpers: FormikHelpers<ProductFormValues>
  ) => {
    try {
      setLoading(true);
      setError("");
      const product: ProductFormValues = {
        name: values.name,
        code: values.code,
        min_price: values.min_price,
        max_price: values.max_price,
        sales_price: values.sales_price,
        best_seller: bestSeller,
        whole_sell_price: values.whole_sell_price,
        category: values.category,
        coupon: values.coupon,
        discount: values.discount,
        featured: featured,
        hot_deals: hotDeals,
        stock_quantity: values.stock_quantity,
        popular: popular,
        tax: values.tax,
        images: featuredImage,
        description: values.description,
      };

      const response = await addProduct(product);
      if (response.status === 400) {
        setError(response.data.message);
        setLoading(false);
        return;
      }
      if (response?.data.output) {
        await uploadImages(response.data.output.id, selectedImage);

        setSuccessMessage("Product added successfully");
        setLoading(false);
        window.scrollTo(0, 0);

        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        formikHelpers.resetForm({
          values: {
            name: "",
            code: "",
            min_price: 0,
            max_price: 0,
            sales_price: 0,
            whole_sell_price: 0,
            best_seller: 0,
            hot_deals: 0,
            featured: 0,
            popular: 0,
            stock_quantity: 0,
            coupon: 0,
            discount: 0,
            tax: 0,
            images: "",

            description: "",
            category: [],
          },
        });
        setCategory([]);
        setLoading(false);
        window.location.reload();
      } else {
        setError("An error occurred");
        setLoading(false);
      }
    } catch (error) {
      setError("An error occurred while adding the product");
      setLoading(false);
    }
  };

  return (
    <>
      <Layout />
      <div className="layout-wrapper">
        <Header />
        {productsModule === false ? (
          <AccessDeniedWithLoader />
        ) : (
          <div className="content row">
            <div className=" col-md-10">
              <div className="card mb-4">
                <div className="card-body ">
                  <div className="row">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ values, setFieldValue }) => (
                        <Form
                          onKeyDown={(e) =>
                            e.key === "Enter" && e.preventDefault()
                          }
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                id="checkout-form-wizard"
                                role="application"
                                className="wizard clearfix"
                              >
                                <div className="content clearfix">
                                  <h3
                                    id="checkout-form-wizard-h-0"
                                    tabIndex={-1}
                                    className="title current"
                                  >
                                    Add Products
                                  </h3>
                                  {successMessage && (
                                    <div
                                      className="alert alert-success"
                                      role="alert"
                                    >
                                      {successMessage}
                                    </div>
                                  )}
                                  {errorMessage && (
                                    <div
                                      className="alert alert-danger"
                                      role="alert"
                                    >
                                      {errorMessage}
                                    </div>
                                  )}

                                  <div className="row g-4 mb-3">
                                    <div className="col-md-4">
                                      <div className="my-2">
                                        <label className="pb-3">Code</label>
                                        <Field
                                          className="form-control"
                                          placeholder="Enter product code "
                                          type="text"
                                          name="code"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="code" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="my-2">
                                        <label className="pb-3">Name</label>
                                        <Field
                                          className="form-control"
                                          placeholder="Enter product name "
                                          type="text"
                                          name="name"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="name" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="my-2">
                                        <label className="pb-3">
                                          Stock Availability
                                        </label>
                                        <Field
                                          as="select"
                                          className="form-control"
                                          defaultValue="yes"
                                          name="isStockAvailable"
                                          onChange={(e: any) => {
                                            if (e.target.value === "yes") {
                                              setFieldValue(
                                                "stock_quantity",
                                                1
                                              );
                                            } else {
                                              setFieldValue(
                                                "stock_quantity",
                                                0
                                              );
                                            }
                                          }}
                                        >
                                          <option value="yes">Yes</option>
                                          <option value="no">No</option>
                                        </Field>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-4 mb-3">
                                    <div className="col-md-3">
                                      <div className="my-2">
                                        <label className="pb-3">
                                          Sale Price
                                        </label>
                                        <Field
                                          className="form-control"
                                          placeholder=" Enter min price "
                                          type="number"
                                          name="min_price"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="min_price" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="my-2">
                                        <label className="pb-3">MRP</label>
                                        <Field
                                          className="form-control"
                                          placeholder=" Enter max price "
                                          type="number"
                                          name="max_price"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="max_price" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="my-2">
                                        <label className="pb-3">B Price</label>
                                        <Field
                                          className="form-control"
                                          placeholder=" Enter Whoesell price "
                                          type="number"
                                          name="sales_price"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="sales_price" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="my-2">
                                        <label className="pb-3">C Price</label>
                                        <Field
                                          className="form-control"
                                          placeholder=" Enter Wholesell Sales price "
                                          type="number"
                                          name="whole_sell_price"
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="whole_sell_price" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row g-4 mb-3">
                                    <div className="col-md-3">
                                      <div className="my-3">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="best_seller"
                                            name="best_seller"
                                            onChange={handleCheckboxChange}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="best_seller"
                                          >
                                            Best Seller
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="my-3">
                                        <div className="form-check">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="hot_deals"
                                            name="hot_deals"
                                            onChange={handleCheckboxChange}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="hot_deals"
                                          >
                                            Hot Deals
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="my-2">
                                        <div className="my-3">
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              value=""
                                              id="featured"
                                              name="featured"
                                              onChange={handleCheckboxChange}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="featured"
                                            >
                                              Featured
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="my-2">
                                        <div className="my-3">
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              value=""
                                              id="popular"
                                              name="popular"
                                              onChange={handleCheckboxChange}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="popular"
                                            >
                                              Popular
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row g-4-mb-3">
                                    <div className="col-md-6">
                                      <div className="my-2">
                                        <label className="pb-3">
                                          Featured Image
                                        </label>
                                        <Field
                                          className="form-control"
                                          type="file"
                                          name="image"
                                          onChange={(event: any) => {
                                            const file = event.target.files[0];
                                            setFeaturedImage(file);
                                          }}
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="image" />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="my-2">
                                        <label className="pb-3">
                                          Other Images
                                        </label>
                                        <Field
                                          className="form-control"
                                          type="file"
                                          name="image"
                                          multiselect={true}
                                          multiple={true}
                                          onChange={(event: any) => {
                                            const file =
                                              event.currentTarget.files;
                                            if (file) {
                                              setSelectedImage(file);
                                            }
                                          }}
                                        />
                                        <div style={{ color: "red" }}>
                                          <ErrorMessage name="image" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="my-2">
                                      <label className="pb-3"> Category</label>
                                      <Multiselect
                                        className="form-control"
                                        options={category}
                                        displayValue="category_name"
                                        selectionLimit={10}
                                        onSearch={handleSelectChange}
                                        onSelect={(selectedList) => {
                                          const selectedCategories =
                                            selectedList.map(
                                              (item: any) => item.category_id
                                            );
                                          setFieldValue(
                                            "category",
                                            selectedCategories
                                          );
                                        }}
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="category" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 d-none">
                                    <div className="my-2">
                                      <label className="pb-3"> Coupon</label>
                                      <Field
                                        className="form-control"
                                        placeholder="Category"
                                        type="number"
                                        name="coupon"
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="coupon" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 d-none">
                                    <div className="my-2">
                                      <label className="pb-3"> Discount</label>
                                      <Field
                                        className="form-control"
                                        placeholder="Category"
                                        type="number"
                                        name="discount"
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="discount" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 d-none">
                                    <div className="my-2">
                                      <label className="pb-3"> Tax</label>
                                      <Field
                                        className="form-control"
                                        placeholder="Category"
                                        type="number"
                                        name="tax"
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="tax" />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="my-2">
                                      <label className="pb-3">
                                        {" "}
                                        Description
                                      </label>
                                      <textarea
                                        cols={50}
                                        rows={5}
                                        className="form-control"
                                        placeholder="Description"
                                        name="description"
                                        value={values.description}
                                        onChange={(e) =>
                                          setFieldValue(
                                            "description",
                                            e.target.value
                                          )
                                        }
                                      />
                                      <div style={{ color: "red" }}>
                                        <ErrorMessage name="description" />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-center my-3">
                                    <button
                                      disabled={loading}
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      Add Product
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
}

export default AddProduct;
